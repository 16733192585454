<template>
  <div></div>
</template>

<script>
import { Loading, Message } from 'element-ui'
import { authLogin, taskInterface } from '@/api/user'
import { accountPermission } from '@/api/manage'

export default {
  name: 'AuthLogin',
  data() {
    return {
      loading: false
    }
  },
  created() {
    var ref = ''
    if (document.referrer.length > 0) {
      ref = document.referrer
    }
    try {
      if (ref.length == 0 && opener.location.href.length > 0) {
        ref = opener.location.href
      }
    } catch (e) {}

    sessionStorage.setItem('websiteSource', ref)
  },
  mounted() {
    this.doAuthLogin()
  },
  methods: {
    doAuthLogin() {
      let that = this
      const loadingInstance = Loading.service({ fullscreen: true })

      const { origin, auth } = this.$route.query
      if (origin && auth) {
        let info = {
          origin: origin,
          auth: auth
        }
        this.$store.dispatch('user/login', info).then(res => {
          loadingInstance.close()
          this.$store.dispatch('user/getTask').then(res => {
            if (res.status == 200) {
              this.$router.push('/')
            }
          })
          accountPermission().then(res => {
            if (res.status == 200) {
              let arr = res.data.data.permissions_str_list
              if (arr.indexOf('任务调度') > -1) {
                sessionStorage.setItem('juHeRoles', true)
              }
            }
          })
        })
      }
      // if (origin && auth) {
      //   let info = {
      //     origin: origin,
      //     auth: auth
      //   }
      //   await authLogin(info).then(res => {
      //     // window.localStorage.setItem('roles', resp.roles)
      //     sessionStorage.setItem('userInfo', JSON.stringify(res.data.data))
      //     that.$store.commit('user/SET_INFO')
      //     window.localStorage.setItem('origin', window.document.referrer)
      //     loadingInstance.close()
      //   })

      //   await taskInterface().then(resp => {
      //     sessionStorage.setItem(
      //       'taskInterface',
      //       JSON.stringify(resp.data.data)
      //     )
      //     that.$store.commit('user/SET_interface')
      //   })
      //   debugger
      //   this.$router.push('/')
      // } else {
      //   loadingInstance.close()
      //   Message.error('第三方登录参数缺失')
      // }
    }
  }
}
</script>
